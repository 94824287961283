<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card">
          <dx-util-tab-panel :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true">
            <dx-util-panel-item title="Email Subscriptions" icon="icon icon-feather-mail">
              <template #default>
                <mail-topic-company />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Notification Subscriptions" icon="icon icon-feather-bell">
              <template #default>
                <notification-subscribe />
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailTopicCompany from '@/views/apps/mail-management/mail-topic-company/MailTopicCompany'
import NotificationSubscribe from '@/views/apps/notification/NotificationSubscribe'

export default {
  components: {
    NotificationSubscribe,
    MailTopicCompany,
  },
}
</script>
<style lang="scss">
.dx-tab-selected {
  background-color: #1d9bd9;
  .dx-tab-text {
    color: #dceaf1;
  }
  .dx-icon {
    color: #dceaf1;
  }
}

.dx-tabs {
  border-radius: 4px 4px 0 0;
}
.dx-tab {
  border-radius: 4px 4px 0 0;
}
</style>
