import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchAll(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.mailTopicCompany.Controller.findAll(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchByCompanyIdAndMailTopicId(companyId, mailTopicId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.mailTopicCompany.Controller.fetchByCompanyIdAndMailTopicId(companyId, mailTopicId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.mailTopicCompany.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  // create mailTopicCompany
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.mailTopicCompany.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update mailTopicCompany
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.mailTopicCompany.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateCompanyMailTopicSubcribedUsers(companyId, mailTopicId, userIdList) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.mailTopicCompany.Controller.updateCompanyMailTopicSubcribedUsers(companyId, mailTopicId), userIdList)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  // delete mailTopicCompany
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.mailTopicCompany.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
