<template>
  <div class="container-fluid ">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="d-flex flex-column justify-content-start">
              <div class="mb-1">
                <h5 class="text-primary">
Notification Topics
</h5>
              </div>
              <div class="mb-1">
                <dx-util-select-box
                  id="companyTypeSelectBox"
                  v-model="notificationTopicName"
                  placeholder="Select Notification Topic"
                  :data-source="notificationTopics"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="notificationTopicValueChanged"
                />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div v-if="currentExplanation">
             <div class="ml-1">
                <h5 class="text-primary">
Notification Explanations
</h5>
              </div>
            <div class="p-1 py-2 d-flex flex-column">
              {{ currentExplanation }}
            </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div v-if="companyUserNames.length > 0" class="mb-1 mx-2">
        <h5 class="text-primary">
User List
</h5>
        <div class="d-flex flex-row flex-wrap">
          <dx-util-check-box v-for="(user, index) in companyUserNames" :key="index"
            :name="user.id.toString()"
            :value="user.subscribed" :text="user.text"
            class="mg-sm col-2"
            @option-changed="onUserSelectionChange"
           />
          </div>
        <div class="d-flex justify-content-end p-1">
          <dx-util-button type="success" text="Save" @click="onSave" />
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// import { JsonToQueryString } from '@/@robustshell/utils'
import { getUserData } from '@/auth/utils'
import ArrayStore from 'devextreme/data/array_store'
import notificationService from '@/http/requests/notification/notificationService'

// BUNUNLA İLGİLİ ENUM YOK, DATABASEDEN ÇEKİYOR. ENDPOINT VAR. ŞİMDİLİK BURAYA EKLEDİM.
const notificationExplanations = {
  PROBLEM: 'Send a notification when a problem is created by the warehouse about your inventory.',
  BATCH_SHIPMENT: 'Send a notification when a shipment send to a destination.',
  ITEM_QUANTITY_CHANGE: 'Send a notification when in any changes in your inventory.',
  INBOUND_ITEM_STATUS_CHANGE: 'Send a notification when your shipped items arrive. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum sit amet. Send a notification when your shipped items arrive. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum sit amet. ',
}
export default {
  data: () => ({
    listAttributes: {
      id: 'users',
    },
    listAttributes2: {
      id: 'unSubscribedUsers',
    },
    subscribedUserNames: [],
    companyUserNames: [],
    selectedItemKeys: [],
    notificationExplanations,
    notificationTopicName: null,
    notificationTopicSubscribe: {
      id: null,
      topic: null,
      company: null,
      users: null,
      companyUsers: null,
    },
    notificationTopicSubscribeDefault: {},
    notificationTopics: [],
  }),
  computed: {
    companyUsers() {
      return new ArrayStore({
        key: 'id',
        displayExpr: 'text',
        data: this.companyUserNames,
        selectedItemKeys: this.selectedItemKeys,
      })
    },
    currentExplanation() {
      return this.notificationExplanations[this.notificationTopicName]
    },
  },
  mounted() {
    this.getNotificationTopicList()
    this.notificationTopicSubscribeDefault = { ...this.notificationTopicSubscribe }
  },
  methods: {
    // onSelectionChanged(e) {
    //   const addedItems = e.addedItems
    //   const removedItems = e.removedItems
    //   // Handler of the "selectionChanged" event
    //   if (addedItems && addedItems.length > 0) {
    //     addedItems.forEach(item => {
    //       if (!this.selectedItemKeys.includes(item.id)) {
    //         this.selectedItemKeys = [...this.selectedItemKeys, item.id]
    //       }
    //     })
    //   }
    //   if (removedItems && removedItems.length > 0) {
    //     removedItems.forEach(item => {
    //       this.selectedItemKeys = this.selectedItemKeys.filter(
    //         data => data !== item.id,
    //       )
    //     })
    //   }
    // },
    onUserSelectionChange(e) {
      // eslint-disable-next-line no-underscore-dangle
      const userKey = +e.component._props.name
      if (e.value === true) {
        this.selectedItemKeys = [...this.selectedItemKeys, userKey]
      } else {
        this.selectedItemKeys = this.selectedItemKeys.filter(
          data => data !== userKey,
        )
      }
    },
    getNotificationTopicList() {
      notificationService.notificationList().then(result => {
        const data = result.data
        data.forEach(element => {
          this.notificationTopics.push({
            value: element.name,
            text: element.value,
          })
        })
      })
    },
    notificationTopicValueChanged(e) {
      const companyId = getUserData().companyId
      const notificationTopicName = e.value
      if (companyId && notificationTopicName) {
        this.getNotificationSubscription(companyId, notificationTopicName)
      }
    },
    onSave() {
      const companyId = getUserData().companyId
      notificationService.updateCompanyNotificationTopicSubscribedUsers(
        companyId,
        this.notificationTopicName,
        this.selectedItemKeys,
      )
    },
    getNotificationSubscription(companyId, notificationTopicName) {
      const self = this
      this.notificationTopicSubscribe = this.notificationTopicSubscribeDefault
      self.subscribedUserNames = []
      self.selectedItemKeys = []
      self.companyUserNames = []
      notificationService.fetchSubscribedUsersByCompanyIdAndNotificationTopicName(companyId, notificationTopicName)
        .then(result => {
          self.notificationTopicSubscribe = result.data
          self.notificationTopicSubscribe.forEach(user => {
            const textValue = `${user.fullName}`
            self.companyUserNames.push({
              id: user.id,
              text: textValue,
              subscribed: user.subscribed,
            })
            if (user.subscribed) {
              self.subscribedUserNames.push(user.fullName)
              self.selectedItemKeys.push(user.id)
            }
          })
          self.companyUserNames.sort((a, b) => a.text.localeCompare(b.text))
        })
    },
    // toCreateQueryString() {
    //   return JsonToQueryString({
    //     page: 0,
    //     size: 5000,
    //   })
    // },
  },
}
</script>

<style scoped>
.selected-data,
.options {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.selected-data .caption {
  font-weight: bold;
  font-size: 115%;
}

.options .caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option>span {
  width: 124px;
  display: inline-block;
}

.option>.dx-widget {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 350px;
}

.mg-sm {
  margin: 4px 40px 4px 4px;
  text-transform: capitalize;
}
.light-white {
  background-color: #fafafa;
  border-radius: 10px;
}
</style>
