<template>
  <div class="container-fluid ">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="d-flex flex-column justify-content-start">
              <div class="mb-1">
                <h5 class="text-primary">
Email Topics
</h5>
              </div>
              <div class="mb-1">
                <dx-util-select-box
                  id="companyTypeSelectBox"
                  v-model="mailTopicId"
                  placeholder="Select Email Topic"
                  :data-source="mailTopics"
                  display-expr="name"
                  value-expr="id"
                  @value-changed="mailTopicValueChanged"
                />
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div v-if="currentExplanation">
              <div class="ml-1">
                <h5 class="text-primary">
Email Explanations
</h5>
              </div>
            <div class="p-1 py-2 d-flex flex-column">
              {{ currentExplanation }}
            </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div v-if="companyUserNames.length > 0" class="mb-1 mx-2">
        <h5 class="text-primary">
User List
</h5>
        <div class="d-flex flex-row flex-wrap">
          <dx-util-check-box v-for="(user, index) in companyUserNames" :key="index"
            :name="user.id.toString()"
            :value="checkID(user.id)"
            :text="user.text"
            class="p-1 col-3"
            @option-changed="onUserSelectionChange"
           />
          </div>
        <div class="d-flex justify-content-end p-1">
          <dx-util-button type="success" text="Save" @click="onSave" />
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import mailTopicCompanyService from '@/http/requests/mail/mailTopicCompanyService'
import mailTopicService from '@/http/requests/mail/mailTopicService'
import { getUserData } from '@/auth/utils'
import ArrayStore from 'devextreme/data/array_store'

// BUNUNLA İLGİLİ ENUM YOK, DATABASEDEN ÇEKİYOR. ENDPOINT VAR. ŞİMDİLİK BURAYA EKLEDİM.
const mailTopicExplanations = {
  124713996: 'This topic is used to send emails about item quantity changes.',
  124713995: 'This topic is used to send emails about inventory problems.',
}
export default {
  data: () => ({
    listAttributes: {
      id: 'users',
    },
    listAttributes2: {
      id: 'unSubscribedUsers',
    },
    subscribedUserNames: [],
    companyUserNames: [],
    selectedItemKeys: [],
    mailTopicExplanations,
    mailTopicId: null,
    mailTopicCompany: {
      id: null,
      mailTopic: null,
      company: null,
      users: null,
      companyUsers: null,
      extraMailList: '',
    },
    mailTopicCompanyDefault: {},
    mailTopics: [],
    companyUnSubcribedUsers: [],
    companySubcribedUsers: [],
    query: '',
  }),
  computed: {
    companyUsers() {
      return new ArrayStore({
        key: 'id',
        displayExpr: 'text',
        data: this.companyUserNames,
        selectedItemKeys: this.selectedItemKeys,
      })
    },
    currentExplanation() {
      return this.mailTopicExplanations[this.mailTopicId]
    },
  },
  mounted() {
    this.getTopicList()
    this.mailTopicCompanyDefault = { ...this.mailTopicCompany }
  },
  methods: {
    // onSelectionChanged(e) {
    //   const addedItems = e.addedItems
    //   const removedItems = e.removedItems
    //   // Handler of the "selectionChanged" event
    //   if (addedItems && addedItems.length > 0) {
    //     addedItems.forEach(item => {
    //       if (!this.selectedItemKeys.includes(item.id)) {
    //         this.selectedItemKeys = [...this.selectedItemKeys, item.id]
    //       }
    //     })
    //   }
    //   if (removedItems && removedItems.length > 0) {
    //     removedItems.forEach(item => {
    //       this.selectedItemKeys = this.selectedItemKeys.filter(
    //         data => data !== item.id,
    //       )
    //     })
    //   }
    // },
    onUserSelectionChange(e) {
      // eslint-disable-next-line no-underscore-dangle
      const userKey = +e.component._props.name
      if (e.value === true) {
        this.selectedItemKeys = [...this.selectedItemKeys, userKey]
      } else {
        this.selectedItemKeys = this.selectedItemKeys.filter(
          data => data !== userKey,
        )
      }
    },
    getTopicList() {
      mailTopicService.fetchAll('').then(result => {
        const data = result.data.content
        data.forEach(element => {
          this.mailTopics.push({
            id: element.id,
            name: element.description,
          })
        })
      })
    },
    mailTopicValueChanged(e) {
      const companyId = getUserData().companyId
      const mailTopicId = e.value
      if (companyId && mailTopicId) {
        this.getMailTopicCompany(companyId, mailTopicId)
      }
    },
    onSave() {
      const companyId = getUserData().companyId
      mailTopicCompanyService.updateCompanyMailTopicSubcribedUsers(
        companyId,
        this.mailTopicId,
        this.selectedItemKeys,
      )
    },
    getMailTopicCompany(companyId, mailTopicId) {
      const self = this
      this.mailTopicCompany = this.mailTopicCompanyDefault
      self.subscribedUserNames = []
      self.selectedItemKeys = []
      self.companyUserNames = []
      mailTopicCompanyService
        .fetchByCompanyIdAndMailTopicId(companyId, mailTopicId)
        .then(result => {
          self.mailTopicCompany = result.data
          self.mailTopicCompany.users.forEach(user => {
            self.subscribedUserNames.push(user.fullName)
            self.selectedItemKeys.push(user.id)
          })
          self.mailTopicCompany.companyUsers.forEach(item => {
            const textValue = `${item.fullName} ${item.email}`
            self.companyUserNames.push({
              id: item.id,
              text: textValue,
              subscribed: item.subscribed,
            })
          })
          self.companyUserNames.sort((a, b) => a.text.localeCompare(b.text))
        })
    },
    checkID(id) {
      return this.selectedItemKeys.includes(id)
    },
  },
}
</script>

<style>
.selected-data,
.options {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.selected-data .caption {
  font-weight: bold;
  font-size: 115%;
}

.options .caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  width: 124px;
  display: inline-block;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 350px;
}
</style>
